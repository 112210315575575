// File: /www/wwwroot/finetuningmax/react-app/src/WelcomeMessage.js
import React from 'react';

const WelcomeMessage = () => {
  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-950 p-3 rounded-md max-w-xl">
      <h1 className="text-purple-400 text-lg font-medium mb-2">Benvenuto all'Assistente Dataset</h1>
      
      <p className="text-gray-200 text-sm mb-2">
        Benvenuto! Ti guiderò nella creazione di un dataset personalizzato per il tuo servizio clienti.
      </p>

      <div className="bg-purple-900/30 border border-purple-500/20 rounded p-2 mb-2">
        <p className="text-purple-400 text-sm font-medium">⚠️ IMPORTANTE:</p>
        <p className="text-gray-300 text-sm">Per ogni domanda fornisci una singola risposta chiara e completa.</p>
      </div>

      <h2 className="text-purple-400 text-sm font-medium mb-1">Il processo includerà:</h2>
      <ul className="text-gray-200 text-xs mb-2">
        <li className="flex items-center space-x-1">
          <span className="text-purple-400">•</span>
          <span>Circa 50 domande mirate sulla tua attività</span>
        </li>
        <li className="flex items-center space-x-1">
          <span className="text-purple-400">•</span>
          <span>Una sezione finale dedicata ai tuoi prodotti/servizi</span>
        </li>
      </ul>

      <h2 className="text-purple-400 text-sm font-medium mb-1">Suggerimenti per risposte ottimali:</h2>
      <ul className="text-gray-200 text-xs mb-2">
        <li className="flex items-center space-x-1">
          <span className="text-purple-400">•</span>
          <span>Fornisci informazioni dettagliate ma concise</span>
        </li>
        <li className="flex items-center space-x-1">
          <span className="text-purple-400">•</span>
          <span>Usa risposte di lunghezza moderata</span>
        </li>
        <li className="flex items-center space-x-1">
          <span className="text-purple-400">•</span>
          <span>Evita risposte multiple o alternative</span>
        </li>
      </ul>

      <p className="text-gray-200 text-sm">
        Scrivi "Pronto" per iniziare!
      </p>
    </div>
  );
};

export default WelcomeMessage;