// EmbeddingsInterface.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Upload as UploadIcon,
  Loader as LoadingIcon,
  CheckCircle as SuccessIcon,
  AlertCircle as ErrorIcon,
} from 'lucide-react';
import AnalysisWrapper from './AnalysisWrapper';
import AnalysisSelector from './components/analysis/AnalysisSelector';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const EmbeddingsInterface = ({ isDarkMode }) => {
  // Stati esistenti
  const [numClusters, setNumClusters] = useState(5);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [isMultiProcessing, setIsMultiProcessing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [messages, setMessages] = useState([]);
  const [processingStatus, setProcessingStatus] = useState('');
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [suggestedColumns, setSuggestedColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [analysisSuggestions, setAnalysisSuggestions] = useState([]);
  const [selectedAnalysisType, setSelectedAnalysisType] = useState('clustering');
  const [analysisResults, setAnalysisResults] = useState(null);
  const [availableAnalyses, setAvailableAnalyses] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchAvailableAnalyses = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          appendMessage('Token mancante. Effettua nuovamente il login.', 'error');
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/embeddings/available-analyses`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        
        setAvailableAnalyses(response.data.analyses);
      } catch (error) {
        console.error('Errore nel caricamento delle analisi disponibili:', error);
        appendMessage('Errore nel caricamento delle analisi disponibili', 'error');
      }
    };

    fetchAvailableAnalyses();
  }, []);

  useEffect(() => {
    setSelectedColumns(suggestedColumns);
  }, [suggestedColumns]);

  const appendMessage = (message, type = 'info') => {
    setMessages((prevMessages) => [...prevMessages, { text: message, type }]);
  };

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

  const renderClusterControl = () => {
    return (
      <div className="mt-4">
        <label className="block mb-2 text-sm font-medium">
          Numero di Cluster
        </label>
        <input
          type="number"
          min="2"
          max="20"
          value={numClusters}
          onChange={(e) => setNumClusters(Math.max(2, Math.min(20, parseInt(e.target.value) || 2)))}
          className={`w-24 px-3 py-2 border rounded-md ${
            isDarkMode 
              ? 'bg-gray-700 border-gray-600 text-white' 
              : 'bg-white border-gray-300'
          }`}
        />
        <p className="text-sm text-gray-500 mt-1">
          Scegli un numero tra 2 e 20 cluster
        </p>
      </div>
    );
  };

  const renderFileList = () => {
    if (multipleFiles.length === 0) return null;
    
    return (
      <div className="mt-4">
        <h4 className="text-sm font-medium mb-2">File Selezionati ({multipleFiles.length})</h4>
        <ul className={`space-y-2 max-h-40 overflow-y-auto rounded-md ${
          isDarkMode ? 'bg-gray-800' : 'bg-gray-50'
        }`}>
          {multipleFiles.map((file, index) => (
            <li 
              key={index} 
              className={`flex items-center justify-between p-2 ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
              } rounded`}
            >
              <div className="flex items-center">
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-600'
                }`}>{file.name}</span>
                <span className="ml-2 text-xs text-gray-500">
                  ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                </span>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setMultipleFiles(files => files.filter((_, i) => i !== index));
                }}
                className="text-red-500 hover:text-red-700"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => {
      if (file.size > MAX_FILE_SIZE) {
        appendMessage(`File ${file.name} troppo grande. Dimensione massima: ${MAX_FILE_SIZE / (1024 * 1024)}MB`, 'error');
        return false;
      }
      return true;
    });
    
    setMultipleFiles(validFiles);
    setSuggestedColumns([]);
    setSelectedColumns([]);
    setAnalysisSuggestions([]);
  };

  const combineResults = (results) => {
    if (!results || !Array.isArray(results) || results.length === 0) {
      console.warn('Nessun risultato valido da combinare');
      return null;
    }

    if (selectedAnalysisType === 'clustering') {
      try {
        const combinedClusters = {};
        const combinedSummaries = {};

        results.forEach((result, fileIndex) => {
          const { clusters, cluster_summaries } = result.analysis_result;

          Object.entries(clusters).forEach(([clusterId, documents]) => {
            if (!combinedClusters[clusterId]) {
              combinedClusters[clusterId] = [];
            }
            combinedClusters[clusterId].push(...documents);
            console.log(`Added ${documents.length} documents to cluster ${clusterId}`);
          });

          Object.entries(cluster_summaries).forEach(([clusterId, summary]) => {
            if (!combinedSummaries[clusterId]) {
              combinedSummaries[clusterId] = { ...summary };
              console.log(`Initialized summary for cluster ${clusterId}`);
            } else {
              combinedSummaries[clusterId]['Numero di elementi'] += summary['Numero di elementi'] || 0;
              combinedSummaries[clusterId]['Files'] = Array.from(new Set([...combinedSummaries[clusterId]['Files'], ...summary['Files']]));
              console.log(`Updated summary for cluster ${clusterId}`);
            }
          });
        });

        return {
          clusters: combinedClusters,
          cluster_summaries: combinedSummaries,
        };
      } catch (error) {
        console.error('Errore nella combinazione dei risultati:', error);
        return null;
      }
    } else if (selectedAnalysisType === 'sentiment' || selectedAnalysisType === 'semantic_search') {
      // Per sentiment e semantic_search, utilizza direttamente l'ultimo risultato
      return results[results.length - 1].analysis_result;
    }

    return null;
  };

  const handleFileUpload = async (columns = []) => {
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', 'error');
      return;
    }

    if (multipleFiles.length === 0) {
      appendMessage('Per favore, seleziona almeno un file.', 'error');
      return;
    }

    setIsMultiProcessing(true);
    setProcessingStatus('Elaborazione file in corso...');

    try {
      const results = [];
      for (let i = 0; i < multipleFiles.length; i++) {
        const file = multipleFiles[i];
        setProcessingStatus(`Elaborazione file ${i + 1} di ${multipleFiles.length}: ${file.name}`);
        
        const formData = new FormData();
        formData.append('file', file);
        formData.append('analysis_type', selectedAnalysisType);
        
        if (selectedAnalysisType === 'clustering') {
          formData.append('num_clusters', numClusters.toString());
        }
        
        if (columns.length > 0) {
          columns.forEach(col => formData.append('selected_columns', col));
        }

        if (selectedAnalysisType === 'semantic_search' && searchQuery) {
          formData.append('analysis_params', JSON.stringify({ query: searchQuery }));
        }

        const response = await axios({
          method: 'post',
          url: `${API_BASE_URL}/embeddings/process-file`,
          data: formData,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          timeout: 300000,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });

        console.log("Risposta API completa:", JSON.stringify(response.data, null, 2));
        
        if (response.data && response.data.status === 'success') {
          const analysisResult = response.data.analysis_result;
          
          results.push({
            analysis_type: selectedAnalysisType,
            analysis_result: analysisResult,
            suggested_columns: response.data.suggested_columns || [],
            analysis_suggestions: response.data.analysis_suggestions || []
          });
        } else {
          console.log(`Risultato invalido per il file ${i}`);
        }
      }

      // Gestisci i risultati dell'analisi solo se ci sono risultati validi
      if (results.length > 0) {
        let combinedResult = null;
        
        if (selectedAnalysisType === 'clustering') {
          combinedResult = combineResults(results);
        } else if (selectedAnalysisType === 'sentiment' || selectedAnalysisType === 'semantic_search') {
          // Per sentiment e semantic_search, utilizza direttamente l'ultimo risultato
          combinedResult = results[results.length - 1].analysis_result;
        }
        
        if (combinedResult) {
          setAnalysisResults(combinedResult); // Imposta direttamente per clustering o altri tipi
          console.log('Analysis Results:', combinedResult); // Log dei risultati

          if (selectedAnalysisType !== 'clustering') {
            setSuggestedColumns(Array.from(new Set(results.flatMap(r => r.suggested_columns || []))));
            setAnalysisSuggestions(Array.from(new Set(results.flatMap(r => r.analysis_suggestions || []))));
          }

          appendMessage('Tutti i file sono stati elaborati con successo', 'success');
        } else {
          appendMessage('Errore nella combinazione dei risultati', 'error');
        }
      } else {
        appendMessage('Nessun risultato valido ricevuto dal server', 'error');
      }

    } catch (error) {
      console.error("Errore durante la richiesta:", error);
      if (error.response?.status === 401) {
        appendMessage('Sessione scaduta. Effettua nuovamente il login.', 'error');
        return;
      }
      appendMessage(error.response?.data?.detail || 'Errore durante l\'elaborazione dei file', 'error');
    } finally {
      setIsMultiProcessing(false);
      setUploadProgress(0);
      setProcessingStatus('');
    }
  }

  const handleAnalysisTypeChange = (type) => {
    setSelectedAnalysisType(type);
    setAnalysisResults(null); // Reset dei risultati quando si cambia tipo
  };

  const handleSemanticSearch = async (query) => {
    setSearchQuery(query);
    if (multipleFiles.length > 0) {
      await handleFileUpload(selectedColumns);
    }
  };

  const handlePointClick = (point) => {
    setSelectedPoint(point);
  };

  const renderProcessingStatus = () => {
    if (isProcessing || processingStatus || isMultiProcessing) {
      return (
        <div className="mt-4">
          <div className={`p-4 rounded-md ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
            <div className="flex items-center">
              {(isProcessing || isMultiProcessing) && (
                <div className="mr-3">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500" />
                </div>
              )}
              <span className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                {processingStatus}
              </span>
            </div>
            {uploadProgress > 0 && (
              <div className="mt-2">
                <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className={`bg-blue-600 h-2.5 rounded-full`}
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
                <span className="text-sm mt-1">{uploadProgress}%</span>
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderColumnSelector = () => {
    if (!suggestedColumns || suggestedColumns.length === 0) {
      return null;
    }
    return (
      <div className="mt-6">
        <h3 className="text-lg font-medium mb-2">Colonne Suggerite</h3>
        <p className="text-sm mb-4 text-gray-600 dark:text-gray-300">
          Il dataset contiene colonne che potrebbero essere analizzate. Seleziona quelle che preferisci.
        </p>
        <div className="mb-4">
          {suggestedColumns.map((col) => (
            <label key={col} className="flex items-center mb-2">
              <input
                type="checkbox"
                className="mr-2"
                checked={selectedColumns.includes(col)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedColumns((prev) => [...prev, col]);
                  } else {
                    setSelectedColumns((prev) => prev.filter(c => c !== col));
                  }
                }}
              />
              <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>{col}</span>
            </label>
          ))}
        </div>
        <button
          onClick={() => handleFileUpload(selectedColumns)}
          disabled={isProcessing || isMultiProcessing || selectedColumns.length === 0}
          className={`px-4 py-2 rounded-md flex items-center ${
            (isProcessing || isMultiProcessing || selectedColumns.length === 0)
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white`}
        >
          {(isProcessing || isMultiProcessing) && <LoadingIcon className="animate-spin h-5 w-5 mr-2" />}
          {(isProcessing || isMultiProcessing) ? 'Rielaborazione...' : 'Rielabora con colonne selezionate'}
        </button>
      </div>
    );
  };

  const renderAnalysisSelector = () => {
    if (!availableAnalyses || Object.keys(availableAnalyses).length === 0) return null;

    return (
      <AnalysisSelector
        availableAnalyses={availableAnalyses}
        selectedAnalysis={selectedAnalysisType}
        onAnalysisChange={handleAnalysisTypeChange}
        isDarkMode={isDarkMode}
      />
    );
  };

  const renderAnalysisControls = () => {
    switch (selectedAnalysisType) {
      case 'clustering':
        return renderClusterControl();
      case 'semantic_search':
        return (
          <div className="mt-4">
            <label className="block mb-2 text-sm font-medium">
              Query di Ricerca
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`w-full px-3 py-2 border rounded-md ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white' 
                  : 'bg-white border-gray-300'
              }`}
              placeholder="Inserisci il testo da cercare..."
            />
          </div>
        );
      default:
        return null;
    }
  };
const renderSelectedPointDetails = () => {
  if (!selectedPoint) return null;
  
  return (
    <div className={`mt-6 p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
      <h3 className="text-lg font-medium mb-2">Dettagli Punto Selezionato</h3>
      <div className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        {Object.entries(selectedPoint).map(([key, value]) => (
          <div key={key} className="mb-1">
            <span className="font-medium">{key}: </span>
            <span>{typeof value === 'object' ? JSON.stringify(value) : value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const renderAnalysisSuggestions = () => {
  if (!analysisSuggestions || analysisSuggestions.length === 0) return null;
  
  return (
    <div className="mt-6">
      <h3 className="text-lg font-medium mb-2">Suggerimenti per l'Analisi</h3>
      <ul className={`list-disc pl-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        {analysisSuggestions.map((suggestion, index) => (
          <li key={index} className="mb-2">{suggestion}</li>
        ))}
      </ul>
    </div>
  );
};
  return (
    <div className={`p-6 rounded-lg shadow-xl ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2">Analisi Documenti</h2>
        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Analizza documenti utilizzando diverse tecniche di elaborazione del testo.
        </p>
      </div>

      {/* Selettore tipo di analisi */}
      {renderAnalysisSelector()}

      {/* Controlli specifici per l'analisi selezionata */}
      {renderAnalysisControls()}

      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium">Carica Documenti</label>
        <label className="flex items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer">
          <div className="flex flex-col items-center">
            <UploadIcon className="h-8 w-8 text-blue-500" />
            <span className="text-gray-500 mt-2">Clicca o trascina per caricare</span>
          </div>
          <input type="file" multiple onChange={handleFileSelect} className="hidden" />
        </label>
        {renderFileList()}
        <button
          onClick={() => handleFileUpload(selectedColumns)}
          disabled={isProcessing || isMultiProcessing || multipleFiles.length === 0}
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center"
        >
          {(isProcessing || isMultiProcessing) && <LoadingIcon className="animate-spin h-5 w-5 mr-2" />}
          {(isProcessing || isMultiProcessing) ? 'Elaborazione...' : 'Analizza File'}
        </button>
      </div>

      {/* Status e progress */}
      {renderProcessingStatus()}
      
      {/* Column selector */}
      {renderColumnSelector()}

      {/* Analysis results */}
      {analysisResults && (
        <AnalysisWrapper
          analysisType={selectedAnalysisType}
          analysisResults={analysisResults}
          isDarkMode={isDarkMode}
          onPointClick={handlePointClick}
          onSearch={handleSemanticSearch}
        />
      )}

      {/* Selected point details */}
      {renderSelectedPointDetails()}

      {/* Analysis suggestions */}
      {renderAnalysisSuggestions()}

      {/* Messages */}
      {messages.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-medium mb-2">Messaggi</h3>
          <ul>
            {messages.map((message, index) => (
              <li key={index} className={`text-sm ${message.type === 'error' ? 'text-red-500' : message.type === 'success' ? 'text-green-500' : 'text-gray-700 dark:text-gray-300'}`}>
                {message.type === 'error' && <ErrorIcon className="inline mr-2" />}
                {message.type === 'success' && <SuccessIcon className="inline mr-2" />}
                {message.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EmbeddingsInterface;
