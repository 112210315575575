import React from 'react';

const AnalysisSelector = ({ 
  availableAnalyses,
  selectedAnalysis,
  onAnalysisChange,
  isDarkMode
}) => {
  return (
    <div className="mt-6">
      <h3 className="text-lg font-medium mb-2">Seleziona Tipo di Analisi</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.entries(availableAnalyses).map(([type, info]) => (
          <button
            key={type}
            onClick={() => onAnalysisChange(type)}
            className={`p-4 rounded-lg border transition-all ${
              selectedAnalysis === type
                ? 'border-blue-500 bg-blue-50 dark:bg-blue-900'
                : 'border-gray-200 dark:border-gray-700 hover:border-blue-300'
            } ${
              isDarkMode ? 'dark:bg-gray-800 dark:text-white' : 'bg-white'
            }`}
          >
            <h4 className="font-medium mb-2">{type.charAt(0).toUpperCase() + type.slice(1)}</h4>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              {info.description}
            </p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AnalysisSelector;