import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './index.css';
import ChatbotHeader from './ChatbotHeader';
import ProductServiceForm from './ProductServiceForm';
import ReactDOMServer from 'react-dom/server';
import WelcomeMessage from './WelcomeMessage';
import { RefreshCw, ArrowUpCircle, Send } from 'lucide-react';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

// Componente per l'anteprima del dataset
const DatasetPreview = ({ data, isDarkMode }) => {
  if (!data || data.length === 0) return null;

  return (
    <div className={`mt-4 rounded-lg overflow-hidden border transition-colors duration-200 ${
      isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
    }`}>
      <div className={`px-4 py-2 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
        <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Anteprima Dataset ({data.length} domande e risposte)
        </h3>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
            <tr>
              <th className={`px-4 py-2 text-left text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>Domanda</th>
              <th className={`px-4 py-2 text-left text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>Risposta</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {data.map((pair, index) => (
              <tr key={index} className={`hover:bg-gray-50 dark:hover:bg-gray-700`}>
                <td className={`px-4 py-2 text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-900'
                }`}>{pair.domanda}</td>
                <td className={`px-4 py-2 text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-900'
                }`}>{pair.risposta}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Chatbot = ({ isDarkMode }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  const [preview, setPreview] = useState(null);
  const [showProductForm, setShowProductForm] = useState(false);
  const [existingQA, setExistingQA] = useState([]);
  const chatMessagesRef = useRef(null);

  const smoothScrollToBottom = useCallback(() => {
    if (chatMessagesRef.current) {
      const element = chatMessagesRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, []);

  useEffect(() => {
    smoothScrollToBottom();
  }, [messages, smoothScrollToBottom, preview]);

  const appendMessage = (message, isUser, preview = null) => {
    const newMessage = { content: message, isUser, preview };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    if (preview) {
      setPreview(preview);
    }
  };

useEffect(() => {
  appendMessage(<WelcomeMessage />, false);
}, []);

  const getAccessToken = () => {
    return localStorage.getItem('token');
  };

  const extractDownloadLink = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const match = content.match(urlRegex);
    return match ? match[0] : null;
  };

  const handleProductSubmit = async (products) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/chatbot/chat`,
        { 
          type: 'products',
          products: products 
        },
        {
          headers: {
            'Authorization': `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const { 
        response: responseMessage, 
        phase, 
        qa_pairs, 
        allQaPairs,
        totalQuestions 
      } = response.data;
      
      setShowProductForm(false);
      
      // Prima mostra il messaggio di conferma
      appendMessage(responseMessage, false);
      
      // Poi, in un nuovo messaggio, mostra la preview delle nuove Q&A generate
      if (qa_pairs && qa_pairs.length > 0) {
        appendMessage(
          "Ecco le nuove domande e risposte generate per i tuoi prodotti:",
          false,
          qa_pairs
        );
        
        // Aggiorna lo stato complessivo delle Q&A
        setExistingQA(allQaPairs);
      }
      
    } catch (error) {
      console.error('Errore durante l\'invio dei prodotti:', error);
      appendMessage(
        'Si è verificato un errore durante l\'elaborazione dei prodotti. Riprova più tardi.',
        false
      );
    } finally {
      setLoading(false);
    }
};

  const sendMessage = async () => {
    if (!input.trim()) return;
    appendMessage(input, true);
    setInput('');
    setLoading(true);

    const authToken = getAccessToken();
    if (!authToken) {
      appendMessage('Sessione scaduta. Effettua nuovamente il login.', false);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/chatbot/chat`, 
        { message: input },
        { 
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const { 
        response: responseMessage, 
        phase, 
        preview, 
        downloadLink: newDownloadLink,
        showProductForm: shouldShowForm,
        existingQA: newExistingQA,
        totalQuestions
      } = response.data;

      if (shouldShowForm) {
        setShowProductForm(true);
        if (newExistingQA) {
          setExistingQA(newExistingQA);
        }
      } else {
        setShowProductForm(false);
      }

      if (newDownloadLink) {
        setDownloadLink(newDownloadLink);
      } else {
        const extractedLink = extractDownloadLink(responseMessage);
        if (extractedLink) setDownloadLink(extractedLink);
      }

      if (phase === 'final') {
        appendMessage(responseMessage, false, preview);
      } else {
        appendMessage(responseMessage, false);
      }

    } catch (error) {
      console.error('Errore durante la chat:', error);
      if (error.response?.status === 401) {
        appendMessage('Errore di autenticazione. Prova a effettuare nuovamente il login.', false);
      } else {
        appendMessage('Si è verificato un errore. Riprova più tardi.', false);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (downloadLink) {
      const token = getAccessToken();
      if (!token) {
        appendMessage('Sessione scaduta. Effettua nuovamente il login prima di scaricare il file.', false);
        return;
      }

      try {
        const response = await axios.get(downloadLink, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dataset.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        appendMessage('Download completato con successo!', false);
      } catch (error) {
        console.error('Errore durante il download del file:', error);
        if (error.response) {
          appendMessage(`Errore durante il download del file: ${error.response.status} - ${error.response.statusText}`, false);
        } else if (error.request) {
          appendMessage('Errore di rete durante il download del file. Verifica la tua connessione e riprova.', false);
        } else {
          appendMessage('Si è verificato un errore imprevisto durante il download del file. Riprova più tardi.', false);
        }
      }
    }
  };
const resetSession = async () => {
  try {
    // Prima facciamo la chiamata API per resettare il server
    const token = localStorage.getItem('token');
    if (token) {
      await axios.post(
        `${API_BASE_URL}/chatbot/reset-session`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );
    }

    // Solo dopo che il server ha resettato con successo, resettiamo lo stato locale
    setMessages([]);
    setInput('');
    setLoading(false);
    setDownloadLink(null);
    setPreview(null);
    setShowProductForm(false);
    setExistingQA([]);
    
    // Aggiungi il messaggio di benvenuto
    appendMessage(<WelcomeMessage />, false);

  } catch (error) {
    console.error('Errore durante il reset della sessione:', error);
    appendMessage('Si è verificato un errore durante il reset della sessione. Riprova più tardi.', false);
  }
};
return (
  <div className={`min-h-screen transition-colors duration-200 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
    <ChatbotHeader isDarkMode={isDarkMode} />
    <div className="container mx-auto p-6">
      <div className={`rounded-lg shadow-xl p-6 transition-colors duration-200 ${
        isDarkMode 
          ? 'bg-gray-800 border border-gray-700' 
          : 'bg-white border border-gray-200'
      }`}>
        {/* Area messaggi chat */}
        <div 
          id="chat-messages" 
          className={`overflow-y-auto mb-4 space-y-4 transition-colors duration-200 h-[500px]
            ${isDarkMode ? 'custom-scrollbar-dark' : 'custom-scrollbar-light'}`}
          ref={chatMessagesRef}
        >
          {messages.map((message, index) => (
            <div key={index}>
              <div
                className={`message p-3 rounded-lg max-w-lg ${
                  message.isUser 
                    ? `self-end ml-auto ${isDarkMode ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-gray-900'}`
                    : `${isDarkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-100 text-gray-900'}`
                } transition-colors duration-200 shadow-md`}
              >
                {message.content}
              </div>
              {message.preview && (
                <DatasetPreview data={message.preview} isDarkMode={isDarkMode} />
              )}
            </div>
          ))}
          {loading && (
            <div className={`message p-3 rounded-lg max-w-lg shadow-md transition-colors duration-200 ${
              isDarkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-100 text-gray-900'
            }`}>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce"></div>
                <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
              </div>
            </div>
          )}
        </div>

        {/* Form dei prodotti */}
        {showProductForm && (
          <div className="mb-6">
            <ProductServiceForm 
              onSubmit={handleProductSubmit}
              isDarkMode={isDarkMode}
              existingQA={existingQA}
            />
          </div>
        )}

        {/* Link di download */}
        {downloadLink && (
          <div className="mb-4">
            <button
              className={`px-4 py-2 rounded-lg transition-colors duration-200 text-white ${
                isDarkMode 
                  ? 'bg-green-600 hover:bg-green-700' 
                  : 'bg-green-500 hover:bg-green-600'
              } shadow-md`}
              onClick={handleDownload}
            >
              Scarica il dataset
            </button>
          </div>
        )}

        {/* Input Area */}
        <div className="p-4 bg-white dark:bg-gray-800 border-t dark:border-gray-700 transition-colors duration-200">
          <div className="flex space-x-4">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
              placeholder="Scrivi un messaggio..."
              className={`flex-1 p-2 border dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200`}
              disabled={loading}
            />
            <button
              onClick={sendMessage}
              disabled={loading || !input.trim()}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 inline-flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span>Invia</span>
              <Send className="h-4 w-4" />
            </button>
            {/* Reset Button */}
            <button
              onClick={resetSession}
              disabled={loading}
              className={`px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200`}
              title="Ricomincia la chat"
            >
              <RefreshCw className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Chatbot;