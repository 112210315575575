import React from 'react';
import { TrendingUp, TrendingDown, Minus } from 'lucide-react';

const SentimentAnalysis = ({ results, isDarkMode }) => {
  // Validazione input e stato predefinito
  if (!results?.results || !results?.summary) {
    return (
      <div className={`w-full rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="p-6">
          <div className={`text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            Nessun risultato disponibile per l'analisi
          </div>
        </div>
      </div>
    );
  }

  const { results: sentimentResults, summary } = results;
  const total = summary.positive + summary.negative + summary.neutral || 0;

  const sentimentConfig = {
    POSITIVE: {
      icon: TrendingUp,
      color: 'text-green-500',
      bgColor: 'bg-green-500',
      label: 'Positivo'
    },
    NEGATIVE: {
      icon: TrendingDown,
      color: 'text-red-500',
      bgColor: 'bg-red-500',
      label: 'Negativo'
    },
    NEUTRAL: {
      icon: Minus,
      color: isDarkMode ? 'text-gray-400' : 'text-gray-600',
      bgColor: 'bg-gray-500',
      label: 'Neutro'
    }
  };

  const getSentimentPercentage = (value) => {
    return total > 0 ? ((value / total) * 100).toFixed(1) : '0.0';
  };

  return (
    <div className="space-y-6">
      {/* Sezione Sommario */}
      <div className={`rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-2">Analisi del Sentiment</h2>
          <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {total} elementi analizzati
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            {Object.entries(summary).map(([type, count]) => {
              const config = sentimentConfig[type.toUpperCase()];
              if (!config) return null;
              
              const percentage = getSentimentPercentage(count);
              const Icon = config.icon;

              return (
                <div 
                  key={type}
                  className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-2">
                      <Icon className={config.color} size={20} />
                      <span className={`font-medium ${config.color}`}>
                        {config.label}
                      </span>
                    </div>
                    <span className={`text-lg font-bold ${config.color}`}>
                      {percentage}%
                    </span>
                  </div>
                  <div className="h-2 w-full bg-gray-200 dark:bg-gray-700 rounded-full">
                    <div
                      className={`h-full ${config.bgColor} rounded-full transition-all duration-500`}
                      style={{ width: `${percentage}%` }}
                    />
                  </div>
                  <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    {count} {count === 1 ? 'elemento' : 'elementi'}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Tabella Dettagli */}
      <div className={`rounded-lg shadow-md overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Testo
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Sentiment
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Score
                </th>
              </tr>
            </thead>
            <tbody className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
              {sentimentResults.map((result) => {
                const sentimentDetails = sentimentConfig[result.sentiment.label] || sentimentConfig.NEUTRAL;
                const Icon = sentimentDetails.icon;

                return (
                  <tr 
                    key={result.id}
                    className={`${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}`}
                  >
                    <td className="px-6 py-4">
                      <div className="max-w-md truncate">
                        {result.text}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center gap-2">
                        <Icon className={sentimentDetails.color} size={16} />
                        <span className={`text-sm font-medium ${sentimentDetails.color}`}>
                          {sentimentDetails.label}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm">
                      {(result.sentiment.score * 100).toFixed(1)}%
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SentimentAnalysis;