import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search, Loader } from 'lucide-react';

const SemanticSearch = ({ results, isDarkMode, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    const query = searchQuery.trim();
    if (!query) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      await onSearch({
        query: query,
        // Altri parametri se necessari possono essere aggiunti qui
      });
    } catch (err) {
      setError(err.message || 'Errore durante la ricerca');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const formatMetadataValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'Sì' : 'No';
    }
    if (typeof value === 'number') {
      return value.toLocaleString('it-IT');
    }
    return value;
  };

  return (
    <div className={`mt-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      {/* Header */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold">Ricerca Semantica</h3>
        <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          Cerca documenti simili al testo inserito
        </p>
      </div>
      
      {/* Search Input */}
      <div className={`p-6 rounded-xl shadow-sm ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      }`}>
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <div className={`relative rounded-lg border ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600' 
                : 'bg-white border-gray-200'
            }`}>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Inserisci il testo da cercare..."
                className={`w-full p-3 pr-10 rounded-lg outline-none ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white placeholder-gray-400' 
                    : 'bg-white text-gray-900 placeholder-gray-500'
                }`}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <Search size={20} className="text-gray-400" />
              </div>
            </div>
          </div>
          <button
            onClick={handleSearch}
            disabled={isLoading || !searchQuery.trim()}
            className={`px-6 py-3 rounded-lg font-medium transition-colors ${
              isLoading || !searchQuery.trim()
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            } flex items-center justify-center min-w-[100px]`}
          >
            {isLoading ? (
              <Loader className="animate-spin" size={20} />
            ) : (
              'Cerca'
            )}
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mt-4 p-3 rounded-lg bg-red-100 border border-red-300 text-red-700">
            {error}
          </div>
        )}

        {/* Results */}
        {results?.results && results.results.length > 0 && (
          <div className="mt-8 space-y-6">
            <div className={`text-sm font-medium ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              {results.results.length} risultati trovati per "{results.query || searchQuery}"
            </div>
            {results.results.map((result) => (
              <div 
                key={result.id}
                className={`p-4 rounded-lg transition-all ${
                  isDarkMode 
                    ? 'bg-gray-700 hover:bg-gray-600' 
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
              >
                {/* Result Text */}
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-700'
                }`}>
                  {result.text}
                </p>

                {/* Similarity Bar */}
                <div className="mt-4 flex items-center gap-4">
                  <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className={`h-full rounded-full transition-all ${
                        result.similarity >= 0.8 ? 'bg-green-500' :
                        result.similarity >= 0.6 ? 'bg-blue-500' :
                        'bg-yellow-500'
                      }`}
                      style={{ width: `${result.similarity * 100}%` }}
                    />
                  </div>
                  <span className={`text-sm font-medium ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {(result.similarity * 100).toFixed(1)}% match
                  </span>
                </div>

                {/* Metadata */}
                {result.metadata && Object.keys(result.metadata).length > 0 && (
                  <div className="mt-3 pt-3 border-t border-gray-200 dark:border-gray-600">
                    <div className="flex flex-wrap gap-2">
                      {Object.entries(result.metadata).map(([key, value]) => (
                        <span
                          key={key}
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            isDarkMode 
                              ? 'bg-gray-600 text-gray-200' 
                              : 'bg-gray-200 text-gray-700'
                          }`}
                        >
                          {key}: {formatMetadataValue(value)}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* No Results */}
        {results?.results && results.results.length === 0 && !isLoading && (
          <div className="mt-8 text-center py-8">
            <p className={`text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Nessun risultato trovato per la tua ricerca
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

SemanticSearch.propTypes = {
  results: PropTypes.shape({
    type: PropTypes.string,
    query: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      similarity: PropTypes.number.isRequired,
      metadata: PropTypes.object
    }))
  }),
  isDarkMode: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default SemanticSearch;