import React, { useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import 'chart.js/auto';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, Info } from 'lucide-react';

const ClusteringAnalysis = ({ 
  clusters, 
  clusterSummaries, 
  isDarkMode,
  onPointClick 
}) => {
  const [expandedCluster, setExpandedCluster] = useState(null);

  // Configurazione del grafico a dispersione
  const generateChartData = () => {
    if (!clusters) return null;

    const colors = [
      'rgba(99, 102, 241, 0.7)',   // Indigo
      'rgba(14, 165, 233, 0.7)',   // Sky
      'rgba(168, 85, 247, 0.7)',   // Purple
      'rgba(34, 197, 94, 0.7)',    // Green
      'rgba(249, 115, 22, 0.7)',   // Orange
      'rgba(236, 72, 153, 0.7)',   // Pink
      'rgba(234, 179, 8, 0.7)',    // Yellow
    ];

    return {
      datasets: Object.entries(clusters).map(([clusterId, documents], index) => ({
        label: `Cluster ${clusterId}`,
        data: documents.map((doc) => ({
          x: doc.embedding_2d[0],
          y: doc.embedding_2d[1],
          meta: doc.metadata,
        })),
        backgroundColor: colors[index % colors.length],
        pointRadius: 6,
        pointHoverRadius: 8,
      })),
    };
  };

  // Formatta i valori per la visualizzazione
  const formatValue = (value) => {
    if (typeof value === 'number') {
      return typeof value.toFixed === 'function' ? value.toFixed(2) : value.toString();
    }
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  // Raggruppa i campi del sommario per tipo
  const groupSummaryFields = (summary) => {
    const groups = {
      stats: {},      // Medie e totali
      common: {},     // Valori più comuni
      unique: {},     // Conteggi valori unici
      other: {}       // Altri campi
    };

    Object.entries(summary).forEach(([key, value]) => {
      if (key.startsWith('Media ') || key.startsWith('Totale ')) {
        groups.stats[key] = value;
      } else if (key.startsWith('Valore più comune ')) {
        groups.common[key] = value;
      } else if (key.startsWith('Valori unici ')) {
        groups.unique[key] = value;
      } else {
        groups.other[key] = value;
      }
    });

    return groups;
  };

  return (
    <div className={`space-y-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold">Analisi Cluster</h2>
        <div className="flex items-center space-x-2">
          <Info size={20} className="text-gray-500" />
          <span className="text-sm text-gray-500">
            {Object.keys(clusterSummaries || {}).length} cluster identificati
          </span>
        </div>
      </div>

      {/* Scatter Plot */}
      <div className={`p-6 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
        <h3 className="text-lg font-medium mb-4">Distribuzione dei Cluster</h3>
        <div className="h-96">
          <Scatter
            data={generateChartData()}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                  labels: {
                    color: isDarkMode ? 'white' : 'black',
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle'
                  },
                },
                tooltip: {
                  backgroundColor: isDarkMode ? 'rgb(31, 41, 55)' : 'white',
                  titleColor: isDarkMode ? 'white' : 'black',
                  bodyColor: isDarkMode ? 'white' : 'black',
                  borderColor: isDarkMode ? 'rgb(75, 85, 99)' : 'rgb(229, 231, 235)',
                  borderWidth: 1,
                  padding: 12,
                  callbacks: {
                    label: function(context) {
                      const point = context.raw;
                      const metadata = point.meta || {};
                      return Object.entries(metadata).map(([key, value]) => 
                        `${key}: ${formatValue(value)}`
                      );
                    },
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                  },
                  ticks: {
                    color: isDarkMode ? 'white' : 'black',
                  },
                },
                y: {
                  grid: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                  },
                  ticks: {
                    color: isDarkMode ? 'white' : 'black',
                  },
                },
              },
              onClick: (event, elements) => {
                if (elements.length > 0 && onPointClick) {
                  const element = elements[0];
                  const datasetIndex = element.datasetIndex;
                  const index = element.index;
                  const point = generateChartData().datasets[datasetIndex].data[index];
                  onPointClick(point);
                }
              },
            }}
          />
        </div>
      </div>

      {/* Cluster Cards */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {Object.entries(clusterSummaries || {}).map(([clusterId, summary]) => {
          const groups = groupSummaryFields(summary);
          const isExpanded = expandedCluster === clusterId;

          return (
            <div
              key={clusterId}
              className={`rounded-xl shadow-lg overflow-hidden transition-all duration-200 ${
                isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white'
              }`}
            >
              {/* Card Header */}
              <div 
                className={`px-6 py-4 flex items-center justify-between cursor-pointer ${
                  isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-50 hover:bg-gray-100'
                }`}
                onClick={() => setExpandedCluster(isExpanded ? null : clusterId)}
              >
                <div>
                  <h3 className="text-lg font-semibold">Cluster {clusterId}</h3>
                  <p className="text-sm text-gray-500 mt-1">
                    {summary['Numero di elementi']} elementi
                  </p>
                </div>
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </div>

              {/* Card Content */}
              <div className={`overflow-hidden transition-all duration-200 ${
                isExpanded ? 'max-h-screen' : 'max-h-0'
              }`}>
                <div className="p-6 space-y-6">
                  {/* Statistiche */}
                  {Object.keys(groups.stats).length > 0 && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-500 mb-3">Statistiche</h4>
                      <div className="grid grid-cols-2 gap-4">
                        {Object.entries(groups.stats).map(([key, value]) => (
                          <div 
                            key={key}
                            className={`p-4 rounded-lg ${
                              isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                            }`}
                          >
                            <div className="text-sm text-gray-500">{key}</div>
                            <div className="text-xl font-semibold mt-1">{formatValue(value)}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Valori Comuni */}
                  {Object.keys(groups.common).length > 0 && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-500 mb-3">Valori Più Frequenti</h4>
                      <div className="space-y-2">
                        {Object.entries(groups.common).map(([key, value]) => (
                          <div 
                            key={key}
                            className={`p-3 rounded-lg ${
                              isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                            }`}
                          >
                            <div className="text-sm text-gray-500">{key.replace('Valore più comune ', '')}</div>
                            <div className="font-medium mt-1">{formatValue(value)}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Altri dati rilevanti */}
                  {Object.keys(groups.unique).length > 0 && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-500 mb-3">Diversità</h4>
                      <div className="grid grid-cols-2 gap-4">
                        {Object.entries(groups.unique).map(([key, value]) => (
                          <div 
                            key={key}
                            className={`p-4 rounded-lg ${
                              isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                            }`}
                          >
                            <div className="text-sm text-gray-500">{key.replace('Valori unici ', '')}</div>
                            <div className="text-xl font-semibold mt-1">{value} valori</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ClusteringAnalysis.propTypes = {
  clusters: PropTypes.object.isRequired,
  clusterSummaries: PropTypes.object.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  onPointClick: PropTypes.func,
};

export default ClusteringAnalysis;