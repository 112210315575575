import React from 'react';
import ClusteringAnalysis from './components/analysis/ClusteringAnalysis';
import SentimentAnalysis from './components/analysis/SentimentAnalysis';
import SemanticSearch from './components/analysis/SemanticSearch';

const AnalysisWrapper = ({ 
  analysisType,
  analysisResults,
  isDarkMode,
  onPointClick,
  onSearch
}) => {
  // Logging per debug
  console.log("AnalysisWrapper - Tipo analisi:", analysisType);
  console.log("AnalysisWrapper - Risultati:", analysisResults);

  // Gestione caso nessun risultato
  if (!analysisResults) {
    return (
      <div className={`mt-6 p-4 rounded-lg ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
        <p>Nessun risultato disponibile</p>
      </div>
    );
  }

  // Validazione tipo di analisi
  const validAnalysisTypes = ['clustering', 'sentiment', 'semantic_search'];
  if (!validAnalysisTypes.includes(analysisType)) {
    return (
      <div className={`mt-6 p-4 rounded-lg ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
        <p>Tipo di analisi non supportato: {analysisType}</p>
      </div>
    );
  }

  // Componente wrapper comune
  const AnalysisContainer = ({ children }) => (
    <div className={`mt-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      {children}
    </div>
  );

  // Rendering condizionale basato sul tipo di analisi
  const renderAnalysis = () => {
    switch (analysisType) {
      case 'clustering':
        return (
          <ClusteringAnalysis
            clusters={analysisResults.clusters}
            clusterSummaries={analysisResults.cluster_summaries}
            isDarkMode={isDarkMode}
            onPointClick={onPointClick}
          />
        );
        
      case 'sentiment':
        // Verifica la struttura corretta dei risultati per sentiment
        if (!analysisResults.results || !analysisResults.summary) {
          console.error('Formato risultati sentiment non valido:', analysisResults);
          return (
            <div className="p-4 text-red-500">
              Errore nel formato dei risultati dell'analisi del sentiment
            </div>
          );
        }
        return (
          <SentimentAnalysis
            results={analysisResults}
            isDarkMode={isDarkMode}
          />
        );
        
      case 'semantic_search':
        // Verifica la struttura corretta dei risultati per semantic search
        if (!Array.isArray(analysisResults.results)) {
          console.error('Formato risultati semantic search non valido:', analysisResults);
          return (
            <div className="p-4 text-red-500">
              Errore nel formato dei risultati della ricerca semantica
            </div>
          );
        }
        return (
          <SemanticSearch
            results={analysisResults}
            isDarkMode={isDarkMode}
            onSearch={onSearch}
          />
        );
        
      default:
        return (
          <div className="p-4">
            <p>Tipo di analisi non gestito: {analysisType}</p>
          </div>
        );
    }
  };

  // Rendering finale con container comune
  return (
    <AnalysisContainer>
      {renderAnalysis()}
    </AnalysisContainer>
  );
};

export default AnalysisWrapper;